import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  IconButton,
  Box,
  Typography,
  Modal,
  Button,
  FormControlLabel,
  FormLabel,
  FormControl,
  Radio,
  MenuItem,
  Select,
  Grid,
  useMediaQuery,
  Stack,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, setDoc, Timestamp } from 'firebase/firestore';
import { db } from 'services/firebase';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-hot-toast';
import { categories } from 'utils/bomb';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/authSlice';
import { StyledInput } from 'assets/styles/GlobalStyle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import './ReportForm.styled.css';
import { validationUxoFormSchema } from 'utils/validationSchema';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { nanoid } from 'nanoid';
import SubMapComponent from 'components/common/Map/SubMapComponent';

const UxoReportForm = ({
  openMapModal,
  selectedMapLocation,
  selectedLatLng,
  setSelectedLatLng,
  locationInfo,
  userLocation,
}) => {
  const { id } = useSelector(state => state.report);
  const [loading, setLoading] = useState(false);
  const [, setSelectedLocation] = useState(null);
  const [openTypeBomb, setOpenTypeBomb] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [openSubmit, setOpenSubmit] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenSubmit(false);
    resetForm();
    navigate('/account/my-reports');
  };

  const [inputVideos, setInputVideos] = useState([]);

  const storage = getStorage();

  const handleInputVideoChange = async e => {
    const videoFiles = Array.from(e.currentTarget.files);
    setFieldValue('video', [...(values.video || []), ...videoFiles]);
    setInputVideos([...inputVideos, ...videoFiles]);
  };

  const {
    handleSubmit,
    handleChange,
    values,
    setValues,
    resetForm,
    setErrors,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      timeObserved: null,
      location: '',
      contactMethod: '',
      contactNumber: '',
      bombType: '',
      containment: '',
      withChemicals: '',
      addComment: '',
      resourcesThreatened: '',
      protective: '',
      priority: 0,
      activityObserved: '',
      photo: [],
      video: [],
      pdf: [],
      originalSocialMediaLink: '',
      selectedLatLng,
    },
    validationSchema: validationUxoFormSchema,
    onSubmit: async values => {
      console.log('Form submitted with values:', values);
      try {
        setLoading(true);
        const writeUserData = async (values, selectedLatLng) => {
          const docData = {
            latitude: selectedLatLng.lat,
            longitude: selectedLatLng.lng,
            city: locationInfo.city,
            state: locationInfo.state,
            country: locationInfo.country,
            timeObserved: values.timeObserved
              ? Timestamp.fromDate(new Date(values.timeObserved))
              : Timestamp.fromDate(new Date()),
            contactMethod: values.contactMethod,
            contactNumber: values.contactNumber,
            hazardType: values.hazardType,
            bombType: values.bombType,
            containment: values.containment,
            withChemicals: values.withChemicals,
            resourcesThreatened: values.resourcesThreatened,
            protectiveMeasures: values.protective,
            priority: parseInt(values.priority),
            description: values.addComment,
            reportingApp: 'Sleuth Net',
            photoUrl: [],
            videoUrl: [],
            docPdfUrl: [],
            originalSocialMediaLink: values.originalSocialMediaLink,
            timeReported: Timestamp.fromDate(new Date()),
            reportId: '',
            reportType: 'uxo',
            reportName: 'UXO',
            originalReportUserId: user,
            archived: false,
            verificationStatus: 'not started',
            verifiedByUserId: '',
            verificationTimestamp: Timestamp.fromDate(new Date()),
            verificationUpVotes: 0,
            verificationDownVotes: 0,
            safetyStatus: 'unknown',
            closedForComments: false,
            closedForUpdates: false,
          };

          try {
            const docRef = await addDoc(collection(db, 'Reports'), {
              ...docData,
              reportId: docRef.id
            });

            const uploadPromises = values.photo.map(async (photo, index) => {
              const photoName = `${user}_${index + 1}`;
              const storageRef = ref(
                storage,
                `uxoReport/${docRef.id}/${photoName}`
              );

              await uploadBytes(storageRef, photo);
              return getDownloadURL(storageRef);
            });

            const photoUrls = await Promise.all(uploadPromises);

            if (inputVideos.length > 0) {
              const videoUrls = [];
              let videoCount = 1;
              for (const video of inputVideos) {
                const videoName = `${user}_video_${videoCount}`;
                const videoStorageRef = ref(
                  storage,
                  `uxoReport/${docRef.id}/${videoName}`
                );
                await uploadBytes(videoStorageRef, video);
                const videoUrl = await getDownloadURL(videoStorageRef);
                videoUrls.push(videoUrl);
                videoCount++;
              }
              values.videoUrl = videoUrls;
            }
            const uploadPdfPromises = values.pdf.map(async (pdf, index) => {
              const pdfName = `${user}_pdf_${index + 1}`;
              const storageRef = ref(
                storage,
                `uxoReport/${docRef.id}/${pdfName}`
              );

              await uploadBytes(storageRef, pdf);
              return getDownloadURL(storageRef);
            });

            const pdfUrls = await Promise.all(uploadPdfPromises);

            await setDoc(
              docRef,
              {
                reportId: docRef.id,
                photoUrl: photoUrls || [],
                videoUrl: values.videoUrl || [],
                docPdfUrl: pdfUrls || [],
              },
              { merge: true }
            );

            console.log('Document written with ID: ', docRef.id);
          } catch (error) {
            console.error('Error adding document: ', error);
          }
        };

        await writeUserData(values, selectedLatLng);
        setLoading(false);
        setSelectedLocation(null);
        setOpenSubmit(true);
      } catch (error) {
        setLoading(false);
        toast.error('Error:', error, {
          duration: 4000,
          position: 'top-center',
        });
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (selectedLatLng && selectedLatLng.lat && selectedLatLng.lng) {
      setFieldValue('location', `${selectedLatLng.lat}, ${selectedLatLng.lng}`);
      setFieldValue('selectedLatLng', selectedLatLng);
    }
  }, [selectedLatLng, setFieldValue]);

  const handlePictureClick = (item, categoryName) => {
    setValues({ ...values, bombType: item.name, hazardType: categoryName });
    setOpenTypeBomb(false);
  };
  const handleReset = () => {
    resetForm();
    setSelectedLatLng(null);
  };
  const isFormValid =
    isValid &&
    dirty &&
    selectedLatLng &&
    selectedLatLng.lat &&
    selectedLatLng.lng;

  const isScreenLessThan900px = useMediaQuery('(max-width:899px)');

  return (
    <Box
      noValidate
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      className="ReportFormBox"
    >
      <Typography variant="h2">
        {t('report.createReportTitle')} for <strong>{t(`${id}`)}</strong>
      </Typography>

      <Grid container>
        <Grid item md={6} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              required
              disableFuture
              ampm={false}
              label={t('report.dateTime')}
              name="timeObserved"
              error={Boolean(errors.timeObserved)}
              value={values.timeObserved}
              onChange={date => setFieldValue('timeObserved', date)}
              slotProps={{ textField: { variant: 'standard' } }}
              sx={{
                marginY: 2,
                width: '100%',
                height: '100%',
              }}
            />
          </LocalizationProvider>
        </Grid>

        {isScreenLessThan900px && (
          <Grid item xs={12}>
            <Box
              sx={{
                flex: 1,
                minWidth: 320,
                width: '100%',
                height: 200,
                bgcolor: '#1d1d1d',
                display: 'block',
              }}
            >
              <SubMapComponent
                defaultCenter={selectedMapLocation || userLocation}
                position={selectedMapLocation}
              />
            </Box>
          </Grid>
        )}
        <Grid
          item
          container
          spacing={2}
          sx={{
            mb: 2,
          }}
        >
          <Grid item xs={12} md={8}>
            {selectedMapLocation ? (
              <StyledInput
                fullWidth
                required
                error={Boolean(errors.location && touched.location)}
                onBlur={() => setFieldTouched('location')}
                variant="standard"
                label={t('translation:report.location')}
                name="location"
                value={
                  selectedLatLng
                    ? `${selectedLatLng.lat}, ${selectedLatLng.lng}`
                    : values.location
                }
              />
            ) : (
              <Typography
                sx={{
                  width: '100%',
                  height: '100%',

                  marginTop: 1,
                  textAlign: 'normal',
                }}
              >
                {t('report.location')}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <Button
              fullWidth
              variant="outlined"
              onClick={openMapModal}
              children={t('button.selectLocation')}
              style={{ height: '100%' }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'normal',
              }}
            >
              <FormLabel component="legend" sx={{ mr: 2 }}>
                {t('translation:report.contactMethod')}
              </FormLabel>
              <Box>
                <FormControlLabel
                  style={{ fontSize: '14px' }}
                  control={
                    <Radio
                      size="small"
                      checked={values.contactMethod === 'Do Not Contact'}
                      onChange={handleChange}
                      name="contactMethod"
                      value="Do Not Contact"
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>
                      {t('translation:report.doNotContact')}
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.contactMethod === 'Phone'}
                      onChange={handleChange}
                      name="contactMethod"
                      value="Phone"
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>
                      {t('translation:report.phone')}
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.contactMethod === 'Radio Frequency'}
                      onChange={handleChange}
                      name="contactMethod"
                      value="Radio Frequency"
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>
                      {t('translation:report.radio')}
                    </span>
                  }
                />
              </Box>
            </Box>
            <StyledInput
              sx={{
                mb: 2,
                width: '60%',
              }}
              required
              variant="standard"
              label={t('report.choiceMethod')}
              name="contactNumber"
              onChange={handleChange}
              value={values.contactNumber}
              disabled={values.contactMethod === 'Do Not Contact'}
            />
          </FormControl>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={5}>
            <Select
              fullWidth
              className="CustomSelect"
              id="typeBomb"
              name="typeBomb"
              value={t('report.uxoHazardSelection')}
              displayEmpty
              onChange={event => {
                handleChange(event);
                setSelectedCategory(
                  categories.find(
                    category => category.name === event.target.value
                  )
                );
              }}
            >
              <MenuItem
                value={t('report.uxoHazardSelection')}
                disabled
                sx={{ display: 'none' }}
              >
                {t('report.uxoHazardSelection')}
              </MenuItem>
              {categories.map(({ name, id }) => (
                <MenuItem
                  key={id}
                  value={name}
                  onClick={() => setOpenTypeBomb(true)}
                >
                  {t(`${name}`)}
                </MenuItem>
              ))}
            </Select>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {selectedCategory && (
                <Modal
                  open={openTypeBomb}
                  onClose={() => setOpenTypeBomb(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className="SelectModal">
                    {selectedCategory.items.map(item => (
                      <img
                        className="BombTypeImg"
                        key={item.id}
                        src={item.picture}
                        alt={item.name}
                        onClick={() =>
                          handlePictureClick(item, selectedCategory.name)
                        }
                      />
                    ))}
                  </Box>
                </Modal>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <StyledInput
              disabled
              sx={{
                mb: 2,
              }}
              required
              error={Boolean(errors.bombType && touched.bombType)}
              variant="standard"
              label={t('translation:report.bombType')}
              name="bombType"
              value={values.bombType}
              onChange={handleChange}
              onBlur={() => setFieldTouched('bombType')}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'normal',
              }}
            >
              <FormLabel component="legend" sx={{ mr: 2 }}>
                {t('translation:report.chemical')}
              </FormLabel>
              <Box>
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.withChemicals === 'Yes'}
                      onChange={handleChange}
                      name="withChemicals"
                      value="Yes"
                    />
                  }
                  label={t('translation:report.yes')}
                />
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.withChemicals === 'No'}
                      onChange={handleChange}
                      name="withChemicals"
                      value="No"
                    />
                  }
                  label={t('translation:report.no')}
                />
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.withChemicals === 'Unknown'}
                      onChange={handleChange}
                      name="withChemicals"
                      value="Unknown"
                    />
                  }
                  label={t('translation:report.unknown')}
                />
              </Box>
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            sx={{
              mb: 2,
              // width: '60%',
            }}
            variant="standard"
            label={t('translation:report.containment')}
            name="containment"
            onChange={handleChange}
            value={values.containment}
          />
        </Grid>

        <Grid item xs={12}>
          <StyledInput
            fullWidth
            variant="standard"
            id="resourcesThreatened"
            label={t('translation:report.resources')}
            name="resourcesThreatened"
            autoComplete="off"
            value={values.resourcesThreatened}
            onChange={handleChange}
            sx={{
              mb: 2,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <StyledInput
            fullWidth
            variant="standard"
            id="protective"
            label={t('translation:report.protective')}
            name="protective"
            autoComplete="off"
            value={values.protective}
            onChange={handleChange}
            sx={{
              mb: 2,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <StyledInput
            fullWidth
            variant="standard"
            id="addComment"
            label={t('report.addComment')}
            name="addComment"
            multiline
            maxRows={15}
            autoComplete="off"
            value={values.addComment}
            onChange={handleChange}
            sx={{
              mb: 2,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'normal',
              }}
            >
              <FormLabel
                component="legend"
                sx={{
                  mr: 2,
                }}
              >
                {t('translation:report.priority')}
              </FormLabel>
              <Box>
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.priority === '2'}
                      onChange={handleChange}
                      name="priority"
                      value={2}
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>
                      {t('translation:report.high')}
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.priority === '1'}
                      onChange={handleChange}
                      name="priority"
                      value={1}
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>
                      {t('translation:report.medium')}
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.priority === '0'}
                      onChange={handleChange}
                      name="priority"
                      value={0}
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>
                      {t('translation:report.low')}
                    </span>
                  }
                />
              </Box>
            </Box>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <StyledInput
            fullWidth
            type="url"
            variant="standard"
            id="originalSocialMediaLink"
            name="originalSocialMediaLink"
            label={t('report.socialMediaLink')}
            autoComplete="off"
            value={values.originalSocialMediaLink}
            onChange={handleChange}
            sx={{
              mb: 1,
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box
            sx={{
              borderRadius: 0,
              position: 'relative',
              borderWidth: errors.photo ? 2 : 1,
              borderColor: errors.photo ? '#d32f2f' : '#133a56',
              borderBottom: 'solid 1px #2196f3',
              my: 1,
            }}
          >
            <IconButton
              onClick={() => {
                setFieldValue('photo', []);
                setErrors({ ...errors, photo: '' });
              }}
            >
              <ClearIcon />
            </IconButton>
            <div className="UploadInput">
              {values.photo.length === 0 ? (
                <Typography
                  className="UploadPlaceholder"
                  children={t('report.uploadPhoto')}
                />
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {values.photo.map(file => (
                    <div key={file.name}> - {file.name}; </div>
                  ))}
                </div>
              )}
            </div>
            {errors.photo && (
              <span className="UploadError">{errors.photo} </span>
            )}
            <Button
              variant="outlined"
              component="label"
              startIcon={<AttachFileIcon />}
              sx={{
                borderColor: errors.photo ? '#d32f2f' : '',
                width: 160,
              }}
            >
              <Typography sx={{ mr: 1 }}> {t('button.photo')} </Typography>
              <input
                hidden
                name="photo"
                id="photo"
                type="file"
                accept=".jpg, .jpeg, .png"
                multiple
                onChange={e => {
                  const files = Array.from(e.currentTarget.files);
                  setFieldValue('photo', [...(values.photo || []), ...files]);
                }}
              />
            </Button>
          </Box>
        </Grid>

        <Grid item md={12} xs={12}>
          <Box
            sx={{
              borderRadius: 0,
              position: 'relative',
              borderWidth: errors.video ? 2 : 1,
              borderColor: errors.video ? '#d32f2f' : '#133a56',
              borderBottom: 'solid 1px #2196f3',
              my: 1,
            }}
          >
            <IconButton
              onClick={() => {
                setFieldValue('video', []);
                setInputVideos([]);
                setErrors({ ...errors, video: '' });
              }}
            >
              <ClearIcon />
            </IconButton>
            <div className="UploadInput">
              {inputVideos.length > 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {inputVideos.map(video => (
                    <div key={nanoid()}>{video.name}</div>
                  ))}
                </div>
              ) : (
                <Typography
                  className="UploadPlaceholder"
                  children={t('report.uploadVideo')}
                />
              )}
            </div>
            {errors.video && (
              <span className="UploadError">{errors.video} </span>
            )}
            <Button
              variant="outlined"
              component="label"
              startIcon={<AttachFileIcon />}
              sx={{
                borderColor: errors.video ? '#d32f2f' : '',
                width: 160,
              }}
            >
              <Typography sx={{ mr: 1 }}> {t('button.video')} </Typography>
              <input
                hidden
                multiple
                name="video"
                id="video"
                type="file"
                accept=".mp4, .avi, .mkv"
                onChange={handleInputVideoChange}
              />
            </Button>
          </Box>
        </Grid>

        <Grid item md={12} xs={12}>
          <Box
            sx={{
              borderRadius: 0,
              position: 'relative',
              borderWidth: errors.pdf ? 2 : 1,
              borderColor: errors.pdf ? '#d32f2f' : '#133a56',
              borderBottom: 'solid 1px #2196f3',
              my: 1,
            }}
          >
            <IconButton
              onClick={() => {
                setFieldValue('pdf', []);
                setErrors({ ...errors, pdf: '' });
              }}
            >
              <ClearIcon />
            </IconButton>
            <div className="UploadInput">
              {values.pdf.length > 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {values.pdf.map(file => (
                    <div key={file.name}>{file.name}</div>
                  ))}
                </div>
              ) : (
                <Typography
                  className="UploadPlaceholder"
                  children={t('report.uploadPdf')}
                />
              )}
            </div>
            {errors.pdf && <span className="UploadError">{errors.pdf}</span>}
            <Button
              variant="outlined"
              component="label"
              startIcon={<AttachFileIcon />}
              sx={{
                borderColor: errors.pdf ? '#d32f2f' : '',
                width: 160,
              }}
            >
              <Typography sx={{ mr: 1 }}>{t('button.pdf')}</Typography>
              <input
                hidden
                name="pdf"
                id="pdf"
                type="file"
                accept=".pdf"
                multiple
                onChange={e => {
                  const files = Array.from(e.currentTarget.files);
                  setFieldValue('pdf', [...(values.pdf || []), ...files]);
                }}
              />
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={2} direction="row" marginTop={2}>
            <Button
              variant="contained"
              type="submit"
              style={{ minWidth: 176 }}
              disabled={!isFormValid}
            >
              {loading ? (
                <CircularProgress color={'secondary'} size={24} />
              ) : (
                `${t('button.submit')}`
              )}
            </Button>
            <Button
              variant="outlined"
              type="reset"
              onClick={e => resetForm()}
              children={t('button.clear')}
            />
          </Stack>
        </Grid>
      </Grid>

      <Modal
        open={openSubmit}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="SubmitedReporttModal">
          <IconButton
            aria-label="close"
            sx={{
              zIndex: 1,
              position: 'absolute',
              top: '-20%',
              left: '100%',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{ fontWeight: 'bold', mb: 2 }}
          >
            {t('submission.submissionTitle',{ns:'reports'})}
          </Typography>
          <Typography id="modal-modal-description">
            {t('submission.submissionDescription',{ns:'reports'})}
          </Typography>
          <Button sx={{mt: 4}} onClick={handleClose} variant='outlined'>Close</Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default UxoReportForm;
