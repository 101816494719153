import { useState } from 'react';
import { AlertModal } from 'components/ui/Modal/AlertModal';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import { logOutUser } from 'redux/auth/authSlice';
import { auth, exit } from 'services/firebase';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const UserLogOut = ({ label, styled }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const push = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const logOut = () => {
    dispatch(logOutUser());
    exit(auth);
    handleCloseAlert();
    push('/');
  };

  const handleOpenAlert = () => {
    setOpenModal(true);
  };
  const handleCloseAlert = () => setOpenModal(false);

  return (
    <div style={{ width: '100%' }}>
      <Button onClick={handleOpenAlert} sx={styled}>
        {label}
      </Button>

      <AlertModal
        open={openModal}
        handleClose={handleCloseAlert}
        handleConfirm={logOut}
        text={t('translation:userInfo.wantExit')}
      />
    </div>
  );
};
