import * as yup from 'yup';
import {
  emailRegExp,
  SUPPORTED_FORMATS,
  FILE_SIZE,
  MAX_VIDEO_SIZE,
  SUPPORTED_VIDEO_FORMATS,
} from './constants';

export const schemaSignUpEng = yup.object().shape({
  firstName: yup
    .string()
    .min(3, 'Minimum 3 characters')
    .max(60, 'Maximum 60 characters')
    .typeError()
    .required(),

  secondName: yup.string().min(3).max(60).typeError(),

  email: yup
    .string()
    .email()
    .required()
    .matches(emailRegExp, 'Email must be like: name@mail.com'),

  verifyEmail: yup
    .string()
    .email()
    .required()
    .oneOf([yup.ref('email'), null], "email don't match."),

  birthday: yup.date().required(),

  country: yup.string().required(),

  password: yup.string().min(6).max(32).typeError().required(),
  // .matches(passwordRegExp, 'min: 8 Latin & number, 1 upper, 1 num'),

  // confirmPassword: yup
  //   .string()
  //   .required('please confirm your password')
  //   .oneOf([yup.ref('password'), null], "passwords don't match."),

  agreeToPrivacyPolicy: yup
    .boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});

export const schemaSignInEng = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
    .matches(emailRegExp, 'email must be like: name@mail.com'),

  password: yup.string().min(6).max(32).required(),
});

export const schemaSignUpUa = yup.object().shape({
  firstName: yup
    .string()
    .min(3, 'Мінімум 3 символи')
    .max(60, 'Максимум 60 символів')
    .typeError('Має бути рядком')
    .required("Обов'язкове поле"),

  secondName: yup
    .string()
    .min(3, 'Мінімум 3 символи')
    .max(60, 'Максимум 60 символів')
    .typeError('Має бути рядком'),

  email: yup
    .string()
    .email('Введіть вірний email')
    .required("Обов'язкове поле"),

  verifyEmail: yup
    .string()
    .email()
    .required()
    .oneOf([yup.ref('email'), null], 'email не збігається.'),
  // phone: yup.string(),
  // .matches(phoneRegExp, 'Номер повинен починатися з коду України +380')
  // .min(13, 'Номер повинен містити + і 12 цифр')
  // .max(13, 'Номер повинен містити + і 12 цифр'),
  // city: yup.string().required("Обов'язкове поле"),
  // state: yup.string(),
  birthday: yup.date().required("Обов'язкове поле"),

  country: yup.string().required("Обов'язкове поле"),

  password: yup
    .string()
    .min(6, 'Мінімум 6 символів')
    .max(32, 'Максимум 32 символи')
    .typeError('Має бути рядкомй')
    .required("Обов'язкове поле"),
  // .matches(
  //   passwordRegExp,
  //   'Тільки латиниця та цифри (мін: 8 символів, 1 велика, 1 цифра)'
  // ),

  // confirmPassword: yup
  //   .string()
  //   .required("Обов'язкове поле")
  //   .oneOf([yup.ref('password'), null], 'Паролі не збігаються'),

  agreeToPrivacyPolicy: yup
    .boolean()
    .required('Умови повинні бути прийняті.')
    .oneOf([true], 'Умови повинні бути прийняті.'),
});

export const schemaSignInUA = yup.object().shape({
  email: yup
    .string()
    .email('Введіть вірний email')
    .required("Обов'язкове поле"),

  password: yup.string().min(6).max(32).required(),
});

export const schemaReportEng = yup.object().shape({
  date: yup.string().required('Date is required'),
  location: yup.string().required('Location is required'),
});

export const schemaReportUa = yup.object().shape({
  date: yup.string().required("Обов'язкове поле"),
  location: yup.string().required("Обов'язкове поле"),
});

export const schemaContactUsEng = yup.object({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().email('Please, enter a valid email').required('Required'),
  reason: yup.string().required('Required'),
  message_answer: yup.string().required('Required'),
});
export const schemaContactUsUa = yup.object({
  firstName: yup.string().required("Обов'язкове поле"),
  lastName: yup.string().required("Обов'язкове поле"),
  email: yup
    .string()
    .email('Введіть вірний email')
    .required("Обов'язкове поле"),
  reason: yup.string().required("Обов'язкове поле"),
  message_answer: yup.string().required("Обов'язкове поле"),
});

export const schemaChangePasswordEng = yup.object().shape({
  password: yup.string().min(6).max(32).typeError().required(),

  confirm: yup
    .string()
    .required('please confirm your password')
    .oneOf([yup.ref('password'), null], "passwords don't match."),
});

export const schemaChangePasswordUa = yup.object().shape({
  password: yup.string().min(6).max(32).typeError().required(),

  confirm: yup
    .string()
    .required("Обов'язкове поле")
    .oneOf([yup.ref('password'), null], 'Паролі не збігаються'),
});

export const schemaPhoto = yup.object().shape({
  photo: yup
    .array()
    .nullable(true)
    .test({
      name: 'fileSize',
      test: value => {
        if (!value || value.length === 0) {
          return true; // No files, no size limit issue
        }

        // Check the size of each file individually
        const totalSize = value.reduce((acc, file) => acc + file.size, 0);
        return totalSize <= FILE_SIZE;
      },
      message: `Total file size can't exceed 10 Mb`,
    })
    .test(
      'photo size',
      'Each file must be min 70x70',
      value => !value || value.every(file => file.size >= 70 * 70)
    )
    .test({
      name: 'fileType',
      test: value => {
        if (!value || value.length === 0) {
          return true; // No files, no type issue
        }

        // Check the type of each file individually
        return value.every(file => SUPPORTED_FORMATS.includes(file.type));
      },
      message: `Each file must be ${SUPPORTED_FORMATS}`,
    }),
});

export const schemaVideo = yup.object().shape({
  video: yup
    .array()
    .nullable(true)
    .max(10, 'Exceeded maximum number of videos')
    .test('totalSize', 'Total video size is too large', value => {
      if (!value) return true;
      const totalSize = value.reduce(
        (acc, video) => acc + (video ? video.size : 0),
        0
      );
      return totalSize <= MAX_VIDEO_SIZE;
    })
    .test('uniqueFiles', 'Cannot add duplicate files', value => {
      if (!value) return true;
      const fileNames = value.map(video => (video ? video.name : null));
      return new Set(fileNames).size === fileNames.length;
    })
    .of(
      yup
        .mixed()
        .test('fileSize', 'Video size is too large', value => {
          return !value || (value && value.size <= MAX_VIDEO_SIZE);
        })
        .test('fileType', 'Unsupported video format', value => {
          return (
            !value || (value && SUPPORTED_VIDEO_FORMATS.includes(value.type))
          );
        })
    ),
});

export const validationUxoFormSchema = yup.object().shape({
  timeObserved: yup.date().required('Time observed is required'),
  location: yup.string().required('Location is required'),
  bombType: yup.string().required('Bomb type is required'),
  selectedLatLng: yup
    .object()
    .shape({
      lat: yup.number().required('Latitude is required'),
      lng: yup.number().required('Longitude is required'),
    })
    .required('Coordinates are required'),
  video: schemaVideo.video,
  photo: schemaPhoto.photo,
});
export const validationFormSchema = yup.object().shape({
  timeObserved: yup.date().required('Time observed is required'),
  location: yup.string().required('Location is required'),
  hazardType: yup.string().required('Hazard type is required'),
  selectedLatLng: yup
    .object()
    .shape({
      lat: yup.number().required('Latitude is required'),
      lng: yup.number().required('Longitude is required'),
    })
    .required('Coordinates are required'),
  video: schemaVideo.video,
  photo: schemaPhoto.photo,
});
