import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  ListItemButton,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { UserLogOut } from 'components/common/UserMenu/UserLogOut';
import { endpoints } from 'utils/constants';
import { setSelectedEndpoint } from 'redux/report/reportSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as MenuIcon } from 'assets/img/menu-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/img/close-menu-icon.svg';
import './SideBar.styled.css';

export const SideBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleMenu = event => {
    event.stopPropagation();
    setIsOpen(prev => !prev);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleOutsideClick = event => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChildItemClick = () => {
    closeMenu();
  };

  return (
    <Box>
      <IconButton onClick={toggleMenu} color="inherit" sx={{ p: 1.5 }}>
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>

      {isOpen && (
        <Box ref={sidebarRef} className="SideBarWrapper">
          <List>
            {/* Trending Section */}
            <ListItem>
              <List>
                <ListItem>
                  <ListItemText
                    sx={{ textTransform: 'uppercase' }}
                    primary={t('ui:mainMenu.trendingTitle')}
                  />
                </ListItem>

                <List sx={{ px: '2rem', textTransform: 'uppercase' }}>
                  <ListItemButton
                    sx={{ py: '4px' }}
                    component={Link}
                    to="/trending/ukraine"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                    {t('UA.name', {ns:'countries'})}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    sx={{ py: '4px' }}
                    component={Link}
                    to="/trending/Israel"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                    {t('IL.name', {ns:'countries'})}

                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    sx={{ py: '4px' }}
                    component={Link}
                    to="/coming-soon"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                      {t('ui:mainMenu.hurricane')}
                    </Typography>
                  </ListItemButton>
                </List>
              </List>
            </ListItem>

            {/* Reports Section */}
            <ListItem>
              <List>
                <ListItem>
                  <ListItemText
                    sx={{ textTransform: 'uppercase' }}
                    primary={t('ui:mainMenu.reportsTitle')}
                  />
                </ListItem>

                <List sx={{ px: '2rem' }}>
                  {endpoints
                    .filter(
                      ({ id }) =>
                        id !== 'nasa' &&
                        id !== 'uxo' &&
                        id !== 'militaryActivity' &&
                        id !== 'warCrime' &&
                        id !== 'terroristActivity'
                    )
                    .map(endpoint => (
                      <ListItemButton
                        key={endpoint.name}
                        component={Link}
                        to={`/report/${endpoint.id}`}
                        sx={{ textTransform: 'uppercase', py: '8px' }}
                        onClick={() => {
                          dispatch(setSelectedEndpoint(endpoint));
                          handleChildItemClick();
                        }}
                      >
                        <Typography fontSize={14}>
                          {t(`${endpoint.name}`)}
                        </Typography>
                      </ListItemButton>
                    ))}
                </List>
              </List>
            </ListItem>

            {/* Forum Section */}
            <ListItem>
              <ListItemButton
                onClick={handleChildItemClick}
                sx={{ color: '#B9B9B9' }}
                component={Link}
                to="/coming-soon"
              >
                <Typography sx={{ textTransform: 'uppercase' }} fontSize={16}>
                  {t('ui:mainMenu.forumTitle')}
                </Typography>
              </ListItemButton>
            </ListItem>

            {/* OSINT Section */}
            <ListItem>
              <ListItemButton
                onClick={handleChildItemClick}
                sx={{ color: '#B9B9B9' }}
                component={Link}
                to="/coming-soon"
              >
                <Typography sx={{ textTransform: 'uppercase' }} fontSize={16}>
                  {t('ui:mainMenu.osintTitle')}
                </Typography>
              </ListItemButton>
            </ListItem>

            {/* Account Section */}
            <ListItem>
              <List>
                <ListItem>
                  <ListItemText
                    sx={{ textTransform: 'uppercase' }}
                    primary={t('ui:mainMenu.accountTitle')}
                  />
                </ListItem>

                <List sx={{ px: '2rem', textTransform: 'uppercase' }}>
                  <ListItemButton
                    sx={{ py: '4px' }}
                    component={Link}
                    to="/account"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                      {t('ui:mainMenu.editAccount')}
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    sx={{ py: '4px' }}
                    component={Link}
                    to="/account/my-reports"
                    onClick={handleChildItemClick}
                  >
                    <Typography fontSize={14}>
                      {t('ui:mainMenu.viewReports')}
                    </Typography>
                  </ListItemButton>
                </List>
              </List>
            </ListItem>

            <Divider />

            {/* Logout */}
            <ListItem>
              <ListItemButton sx={{ padding: 0, mt: 2 }}>
                <UserLogOut
                  label={t('ui:mainMenu.logout')}
                  styled={{
                    width: '100%',
                    textTransform: 'uppercase',
                    justifyContent: 'normal',
                    padding: '8px 16px',
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      )}
    </Box>
  );
};
