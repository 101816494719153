export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const phoneRegExp =
  /^[+]?[\d]{1,3}?[-.\s]?[\d]{1,4}?[-.\s]?[\d]{1,4}?[-.\s]?[\d]{1,9}$/;

export const passwordRegExp =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?!.*\W).*$/;

export const breakPoints = {
  PHONE: 320,
  TABLET: 834,
  DESKTOP: 1280,
  UHD: 1440,
};

export const endpoints = [
  {
    id: 'uxo',
    name: 'UXO',
    description: 'Unexploded bombs, munitions, or explosives',
  },
  {
    id: 'militaryActivity',
    name: 'Military Activity',
    description: 'Military movements',
  },
  {
    id: 'wildlife',
    name: 'Wildlife',
    description: 'Dangerous wild animals',
  },
  {
    id: 'warCrime',
    name: 'War Crime',
    description: 'Crimes committed by military personnel',
  },
  {
    id: 'criminalActivity',
    name: 'Criminal Activity',
    description: 'Civil violence or crime',
  },
  {
    id: 'resourceScarcity',
    name: 'Resource Scarcity',
    description: 'Low supply, descrution, or absence of a resource',
  },
  {
    id: 'structureHazard',
    name: 'Structure Hazard',
    description: 'Building or structure hazards',
  },
  {
    id: 'naturalDisaster',
    name: 'Natural Disaster',
    description: 'Weather events that cause damage',
  },
  {
    id: 'terroristActivity',
    name: 'Terrorist Activity',
    description: 'Terrorist-related activity',
  },
  {
    id: "environmentalHazards",
    name: "Envrionment Hazards",
    description: "Accidents or incidents involving transportation.",
  },
  {
    id: 'nasa',
    name: 'NASA EONet Reports',
    description: 'Autogenerated reports from NASA',
  },
];

export const loadGoogleMapsScript = (apiKey, callback) => {
  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.id = 'googleMaps';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  } else {
    if (callback) callback();
  }
};

export const center_ua = {
  lat: 49.4037,
  lng: 31.40257,
};

export const typeOccupation = [
  {
    id: 'cyber_threat_analysis',
    name: 'Cyber Threat Analysis',
    category: 'Cybersecurity',
  },
  {
    id: 'cybersecurity_operations',
    name: 'Cybersecurity Operations',
    category: 'Cybersecurity',
  },
  {
    id: 'network_security_architecture',
    name: 'Network Security Architecture',
    category: 'Cybersecurity',
  },
  {
    id: 'digital_forensics',
    name: 'Digital Forensics',
    category: 'Cybersecurity',
  },
  {
    id: 'border_security_operations',
    name: 'Border Security Operations',
    category: 'Defense and Security',
  },
  {
    id: 'explosive_ordnance_disposal',
    name: 'Explosive Ordnance Disposal (EOD)',
    category: 'Defense and Security',
  },
  {
    id: 'military_strategy_tactics',
    name: 'Military Strategy and Tactics',
    category: 'Defense and Security',
  },
  {
    id: 'security_intelligence_planning',
    name: 'Security Intelligence Planning',
    category: 'Defense and Security',
  },
  {
    id: 'surveillance_reconnaissance',
    name: 'Surveillance and Reconnaissance',
    category: 'Defense and Security',
  },
  {
    id: 'tactical_training_operations',
    name: 'Tactical Training and Operations',
    category: 'Defense and Security',
  },
  {
    id: 'anti_terrorism_counterterrorism',
    name: 'Anti-Terrorism and Counterterrorism',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'counterintelligence',
    name: 'Counterintelligence',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'cyber_intelligence_analysis',
    name: 'Cyber Intelligence Analysis',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'human_intelligence_humint',
    name: 'Human Intelligence (HUMINT)',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'intelligence_analysis',
    name: 'Intelligence Analysis',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'open_source_intelligence_osint',
    name: 'Open Source Intelligence (OSINT)',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'signals_intelligence_sigint',
    name: 'Signals Intelligence (SIGINT)',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'threat_assessment',
    name: 'Threat Assessment',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'combat_medic_field_medical_services',
    name: 'Combat Medic and Field Medical Services',
    category: 'Medical and Emergency',
  },
  {
    id: 'emergency_response_coordination',
    name: 'Emergency Response Coordination',
    category: 'Medical and Emergency',
  },
  {
    id: 'disaster_response_planning',
    name: 'Disaster Response Planning',
    category: 'Medical and Emergency',
  },
  {
    id: 'medical_intelligence_analysis',
    name: 'Medical Intelligence Analysis',
    category: 'Medical and Emergency',
  },
  {
    id: 'criminal_intelligence',
    name: 'Criminal Intelligence',
    category: 'Law Enforcement',
  },
  {
    id: 'forensic_science',
    name: 'Forensic Science',
    category: 'Law Enforcement',
  },
  {
    id: 'law_enforcement_investigations',
    name: 'Law Enforcement Investigations',
    category: 'Law Enforcement',
  },
  {
    id: 'narcotics_enforcement',
    name: 'Narcotics Enforcement',
    category: 'Law Enforcement',
  },
  {
    id: 'crime_scene_investigation',
    name: 'Crime Scene Investigation',
    category: 'Law Enforcement',
  },
  {
    id: 'special_operations_forces_sof',
    name: 'Special Operations Forces (SOF)',
    category: 'Military',
  },
  {
    id: 'weapons_ammunition_handling',
    name: 'Weapons and Ammunition Handling',
    category: 'Military',
  },
  {
    id: 'military_logistics_support',
    name: 'Military Logistics Support',
    category: 'Military',
  },
  {
    id: 'armored_vehicle_operations',
    name: 'Armored Vehicle Operations',
    category: 'Military',
  },
  {
    id: 'homeland_security',
    name: 'Homeland Security',
    category: 'Security and Protective Services',
  },
  {
    id: 'physical_security_consulting',
    name: 'Physical Security Consulting',
    category: 'Security and Protective Services',
  },
  {
    id: 'protective_services_executive_protection',
    name: 'Protective Services and Executive Protection',
    category: 'Security and Protective Services',
  },
  {
    id: 'risk_assessment_mitigation',
    name: 'Risk Assessment and Mitigation',
    category: 'Security and Protective Services',
  },
  {
    id: 'security_systems_integration',
    name: 'Security Systems Integration',
    category: 'Security and Protective Services',
  },
  {
    id: 'airport_security_management',
    name: 'Airport Security Management',
    category: 'Security and Protective Services',
  },
  {
    id: 'counter_surveillance_techniques',
    name: 'Counter-Surveillance Techniques',
    category: 'Security and Protective Services',
  },
];



export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
export const FILE_SIZE = 10000000; // 10 MB

export const MAX_VIDEO_SIZE = 1024 * 1024 * 100; // 100 MB
export const SUPPORTED_VIDEO_FORMATS = ['video/mp4', 'video/avi', 'video/mkv'];

export const currentDateTime = () => {
  const now = new Date();
  return now.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
};

export const reportDateTime = prop => {
  const date = new Date(prop);
  return date.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
};

export const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of the',
  'Congo, Republic of the',
  'Costa Rica',
  "Cote d'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor (Timor-Leste)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];
